import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'auth',
    loadComponent: () => import('./auth/auth.page').then( m => m.AuthPage)
  },
  {
    path: 'signupconfirm/:memberId/:dob/:email',
    loadComponent: () => import('./content/content.page').then( m => m.ContentPage),
    children: [
      {
        path: '',
        loadComponent: () => import('./sign-up-confirm/sign-up-confirm.component').then( m => m.SignUpConfirmComponent)
      },
    ]
  },
  {
    path: 'passwordcreate/:accessLinkId',
    loadComponent: () => import('./content/content.page').then( m => m.ContentPage),
    children: [
      {
        path: '',
        loadComponent: () => import('./password-create/password-create.component').then( m => m.PasswordCreateComponent)
      },
    ]
  },
  {
    path: 'termsandconditions',
    loadComponent: () => import('./content/content.page').then( m => m.ContentPage),
    children: [
      {
        path: '',
        loadComponent: () => import('./terms-and-conditions/terms-and-conditions.component').then( m => m.TermsAndConditionsComponent)
      },
    ]
  },
  {
    path: 'insurance',
    loadComponent: () => import('./content/content.page').then( m => m.ContentPage),
    children: [
      {
        path: '',
        loadComponent: () => import('./policies-list/policies-list.component').then( m => m.PoliciesListComponent)
      },
      {
        path: 'policy/:id/:role',
        loadComponent: () => import('./census-line-selector/census-line-selector.component').then( m => m.CensusLineSelectorComponent)
      },
      {
        path: 'abi-widget/:uniqueId/:abiId',
        loadComponent: () => import('./abi-widget/abi-widget.component').then( m => m.AbiWidgetComponent)
      }
    ]
  },
  {
    path: '',
    redirectTo: 'insurance',
    pathMatch: 'full',
  },

];
